:root {
  --pad: 1rem;
  --mar: 0.5rem;
  --margin: 0.25rem;
  --gap: 0.5rem;
  --border-radius: 5px;
  --shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  --shadow-blue: 0 4px 8px 0 rgba(128, 183, 255, 0.7),
    0 6px 20px 0 rgba(128, 183, 255, 0.08);
  --lock: #f9f9f9;

  --background: white;
  --text: rgb(100, 100, 100);
  --error: #ff3c2e;
  --warning: #ffa42e;
  --caution: #ffcc8a;
  --dark: rgb(70, 70, 70);
  --primary: rgb(128, 183, 255);
  --secondary: rgb(221, 221, 221);
  --tertiary: rgb(240, 240, 240);
  --invis-borders: #e8e8e8;
  --partial: #dbf4ff;
  --allocated: #8cc63f;
}

* {
  box-sizing: border-box;
}
svg {
  box-sizing: content-box;
}

/* Position */
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

/* Widths */
.card-two-thirds {
  margin: 0 auto;
  width: 66.6666666667%;
}
.card-three-quarters {
  margin: 0 auto;
  width: 75%;
}
.card-almost-full {
  margin: 0 auto;
  width: 95%;
}

.split {
  width: 50%;
}

/* Padding */
.pad {
  padding: var(--pad);
}
.pL {
  padding-left: var(--pad);
}
.pB {
  padding-bottom: var(--pad);
}
.pT {
  padding-top: var(--pad);
}
.pR {
  padding-right: var(--pad);
}

/* Margins */
.mL {
  margin-left: var(--mar);
}
.mB {
  margin-bottom: var(--mar);
}
.mT {
  margin-top: var(--mar);
}
.mR {
  margin-right: var(--mar);
}

/* Errors */
.error-box {
  position: relative;
}

.error-icon {
  position: absolute;
  right: -6px;
  top: -6px;
  padding: 0px;
  font-size: small;
  z-index: 111;
}
.error-icon:hover {
  cursor: pointer;
}
.error-icon:hover + .error-bubble {
  display: block;
}
.error-bubble {
  position: absolute;
  border-radius: var(--border-radius);
  display: none;
  padding: var(--pad);
  font-size: small;
  background-color: var(--error);
  color: white;
  z-index: 10;
}

.error-msg {
  position: absolute;
  left: 0;
  display: none;
  padding: none;
  font-size: small;
  color: var(--error);
}

.warning-msg {
  position: absolute;
  right: 0;
  display: none;
  padding: none;
  font-size: small;
  color: var(--warning);
}

/* Grids */
.grid-bar {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  box-sizing: border-box;
}
.gap {
  grid-column-gap: 1rem;
}

.cell {
  border: 1px solid var(--secondary);
  border-right: none;
}
.cell.last {
  border-right: 1px solid var(--secondary);
}

.grid-bar .full {
  grid-column: span 12;
}
.grid-bar .span-12 {
  grid-column: span 12;
}
.grid-bar .span-11 {
  grid-column: span 11;
}
.grid-bar .span-10 {
  grid-column: span 10;
}
.grid-bar .span-9 {
  grid-column: span 9;
}
.grid-bar .three-quarters {
  grid-column: span 9;
}
.grid-bar .half {
  grid-column: span 6;
}
.grid-bar .third {
  grid-column: span 4;
}
.grid-bar .quarter {
  grid-column: span 3;
}
.grid-bar .two-thirds {
  grid-column: span 8;
}
.grid-bar .span-8 {
  grid-column: span 8;
}

.grid-bar .span-6 {
  grid-column: span 6;
}
.grid-bar .span-5 {
  grid-column: span 5;
}
.grid-bar .span-4 {
  grid-column: span 4;
}
.grid-bar .span-3 {
  grid-column: span 3;
}
.grid-bar .span-2 {
  grid-column: span 2;
}
.grid-bar .span-1 {
  grid-column: span 1;
}
.grid-bar .single {
  grid-column: span 1;
}
.grid-bar .single.s-2 {
  grid-column: 2 / span 1;
}
.grid-bar .single.s-3 {
  grid-column: 3 / span 1;
}
.grid-bar .single.s-4 {
  grid-column: 4 / span 1;
}
.grid-bar .single.s-5 {
  grid-column: 5 / span 1;
}
.grid-bar .single.s-6 {
  grid-column: 6 / span 1;
}
.grid-bar .single.s-7 {
  grid-column: 7 / span 1;
}
.grid-bar .single.s-8 {
  grid-column: 8 / span 1;
}
.grid-bar .single.s-9 {
  grid-column: 9 / span 1;
}
.grid-bar .span-2.s-9 {
  grid-column: 9 / span 2;
}
.grid-bar .span-1.s-10 {
  grid-column: 10 / span 1;
}
.grid-bar .span-1.s-11 {
  grid-column: 11 / span 1;
}
.grid-bar .span-1.s-11 {
  grid-column: 11 / span 1;
}
.grid-bar .span-1.s-12 {
  grid-column: 12 / span 1;
}
.grid-bar .single.s-12 {
  grid-column: 12 / span 1;
}
.grid-bar .full.right,
.grid-bar .half.right,
.grid-bar .third.right,
.grid-bar .quarter.right,
.grid-bar .two-thirds.right,
.grid-bar .span-2.right,
.grid-bar .single.right {
  justify-self: right;
}
.grid-bar .full.center,
.grid-bar .half.center,
.grid-bar .third.center,
.grid-bar .quarter.center,
.grid-bar .two-thirds.center,
.grid-bar .span-2.center,
.grid-bar .single.center {
  justify-self: center;
}

/* Modal */
.modal-page {
  position: fixed;
  display: block;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #000000;
}

.modal-container {
  background-color: #fefefe;
  margin: 5% auto;
  border-radius: 5px;
  width: 80%;
}

.modal-flyin {
  animation: flyin 0.33s;
}

@keyframes flyin {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.modal-header {
  padding: 1rem;
  border-radius: 5px 5px 0 0;
  background-color: var(--tertiary);
}

/* Labels */
.label-primary {
  color: var(--primary);
}
.label-default {
  font-weight: 600;
  font-size: 1rem;
  color: var(--text);
}

/* Inputs */
.input-spacing {
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.input-label {
  font-weight: 600;
  /* margin-top: 0.5rem; */
  margin-right: 1rem;
  font-size: 1rem;
  color: var(--text);
  white-space: nowrap;
}

.input-default {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px;
  width: 100%;
  color: var(--text);
  /* margin-bottom: 0.33rem; */
}

.input-borderless {
  border: none;
  font-size: 11pt;
  padding: 10px;
  width: 100%;
  color: var(--text);
}

.input-default:focus {
  outline: 2px solid var(--primary);
  border: none;
}
.input-required {
  position: absolute;
  left: -10px;
  top: 0;
  color: var(--error);
}

.input-error-box {
  position: relative;
  width: 100%;
}

.input-error {
  margin: 0;
  overflow: hidden;
  height: 1rem;
  position: absolute;
  bottom: -1rem;
  right: 0;
  font-weight: 425;
  color: var(--error);
}

.input-lock {
  margin-bottom: 0;
  transition: margin 0.5s ease-in-out;
}
.input-unlock {
  margin-bottom: 0.33rem;
  transition: margin 0.5s ease-in-out;
}

/* Address */

.address-table {
  height: calc(100vh - 722px);
  border: 2px solid #5e6d75;
  border-radius: 5px;
  color: #5e6d75;
  width: 100%;
  overflow-y: scroll;
}

.address-table-header {
  background-color: var(--secondary);
  color: var(--text);
  border-radius: 5px 5px 0 0;
}

.address-table .row:hover {
  background-color: var(--tertiary);
  color: var(--text);
  cursor: pointer;
}

.address-table .cell {
  border-right: 1px solid var(--secondary);
  border-bottom: 1px solid var(--secondary);
  padding: 10px;
}

/* Select */

.select-default {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  font-size: 11pt;
  color: var(--text);
}

/* Dropdown */
.dropdown {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--background);
  color: var(--text);
}

.dropdown-borderless {
  position: relative;
  display: inline-block;
  border: none;
  background-color: var(--background);
  color: var(--text);
  width: max-content;
}

.dropdown-label {
  padding: 0 0 1rem 0;
}

.dropdown.lock {
  pointer-events: none !important;
}

.dropdown-btn {
  position: relative;
  box-sizing: content-box;
  background-color: var(--background);
  border-radius: var(--border-radius);
  color: var(--text);
  font-size: 11pt;
  height: 30px;
  width: 168px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
}

.dropdown-btn-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-btn.borderless {
  border: none;
}

.dropdown-btn:hover {
  background-color: var(--tertiary);
  color: var(--primary);
}

.dropdown-options-container {
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--background);
  z-index: 5;
  font-size: 11pt;
  color: var(--text);
  width: 100%;
  min-width: max-content;
  overflow-y: scroll;
  max-height: 200px;
}

.dropdown-options-container .dropdown-option {
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  white-space: normal;
  text-overflow: clip;
}

.dropdown-options-container .dropdown-option:hover {
  background-color: var(--tertiary);
}

/* Buttons */
.btn-default {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px 20px;
  line-height: 30px;
  /* width: 100%; */
  color: var(--text);
  background-color: var(--background);
}
.btn-primary {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px 20px;
  line-height: 30px;
  /* width: 100%; */
  color: var(--background);
  background-color: var(--primary);
}
.btn-success {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px;
  /* width: 100%; */
  color: var(--background);
  background-color: var(--allocated);
}
.btn-error {
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 11pt;
  padding: 10px;
  /* width: 100%; */
  color: var(--background);
  background-color: var(--error);
}
.btn-error:hover {
  cursor: pointer;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    outline: var(--error) solid 1px;
  }
  50% {
    outline: var(--error) solid 5px;
  }
  100% {
    outline: var(--error) solid 1px;
  }
}

.btn-link {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: var(--primary);
}
.btn-link:hover {
  text-decoration: underline;
}

.btn-disabled {
  background-color: var(--secondary) !important;
  color: var(--text) !important;
  cursor: default !important;
  border: none !important;
}
.btn-invis {
  background-color: transparent !important;
  color: var(--text) !important;
  border: none !important;
}

/* Hover */
.hover-primary:hover {
  background-color: var(--primary);
  color: var(--background);
}
.hover-success:hover {
  background-color: var(--primary);
  color: var(--background);
}

.btn-success:hover {
  cursor: pointer;
  color: var(--allocated);
  background-color: var(--background);
}

/* Misc */
.icon:hover {
  cursor: pointer !important;
}
.hover-unhide:hover {
  cursor: pointer;
}

.hover-hidden {
  visibility: hidden;
}
.hover-hidden:hover {
  visibility: visible;
}
.hover-unhide:hover ~ .hover-hidden {
  visibility: visible;
}

.titleline {
  border-bottom: 1px solid var(--secondary);
}

.note {
  background-color: var(--tertiary);
  border-radius: 5px;
}

.tab {
  margin: 0;
  padding: 0.5rem;
  background-color: var(--secondary);
}
.tab:hover {
  background-color: var(--tertiary);
  cursor: pointer;
}
.tab.selected {
  background-color: var(--background);
}

.station {
  position: absolute;
  /* overflow: clip; */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--background);
  z-index: 1;
}

.graph {
  position: relative;
  border: 1px solid var(--secondary);
  width: 100%;
  height: 500px;
  margin-bottom: 80px;
}

.graph-bar {
  line-height: 0;
  position: absolute;
  z-index: 0;
}
