abbr {
  text-decoration: none;
}
.react-calendar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.react-calendar__tile {
  display: flex;
  padding: 2rem 0.75rem;
  font-size: 11pt;
  line-height: 1.428571429;
  color: #38464e;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7eef2;
}
/* .react-calendar__tile:hover {
  animation: days 0.5s ease forwards;
}

@keyframes days {
  100% {
    background-color: var(--primary);
  }
}

.react-calendar__tile--now > abbr {
  background-color: var(--primary);
  width: 20px;
  border-radius: 10px;
}
*/
.react-calendar__tile > abbr {
  position: relative;
  top: -1.5rem;
  right: 0.33rem;
}

.react-calendar__navigation {
  display: flex;
}

.react-calendar__navigation__label {
  margin-bottom: 0.5rem;
  padding: 0.33rem 0.75rem;
  font-size: 11pt;
  line-height: 1.428571429;
  color: #38464e;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7eef2;
  border-radius: 5px;
}

.react-calendar__navigation__arrow {
  margin-bottom: 0.5rem;
  padding: 0.33rem 0.75rem;
  font-size: 11pt;
  line-height: 1.428571429;
  color: #38464e;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e7eef2;
  border-radius: 5px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: var(--nav-background);
  color: var(--nav-text);
}
