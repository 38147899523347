.flyin-container {
  position: absolute;
  right: 0;
  top: 25vh;
  width: 200px;
  height: 75vh;
  overflow: hidden;
}

.flyin {
  position: relative;
  width: 200px;
  height: 75px;
  pointer-events: none;
  z-index: 9999;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  border-radius: 5px 0 0 5px;
  animation: flyin 0.3s ease-in-out forwards;
}

.flyin.pending {
  background: var(--background);
  border: 1px solid var(--primary);
}

.flyin.finished {
  color: var(--tertiary);
  background-color: var(--primary);
  border: 1px solid var(--tertiary);
}

.flyin .flyin-content {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.flyin .flyin-msg {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

@keyframes flyin {
  0% {
    left: 200px;
  }
  100% {
    left: 0;
  }
}
