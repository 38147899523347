.alert-modal-off {
  display: none;
}
.alert-modal-on {
  animation: modal 0.5s ease-in forwards;
}

@keyframes modal {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
.alert-modal {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  padding: 10px;
  width: 33vw;
  border-radius: 5px;
  text-align: right;
}
.alert-modal-success {
  color: var(--primary);
}

.alert-modal-error {
  color: var(--error);
}

.alert-modal-warning {
  color: var(--warning);
}

.alert-modal-query {
  color: var(--warning);
}
