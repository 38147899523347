.drag-over {
  animation: shift 0.5s ease;
  animation-fill-mode: forwards;
}
@keyframes shift {
  100% {
    transform: translate(5px, 5px);
  }
}

.drag-leave {
  animation: unshift 0.5s ease;
  animation-fill-mode: forwards;
}
@keyframes unshift {
  0% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.dragging {
  pointer-events: none;
}

.drag-scroll-up {
  position: sticky;
  margin: 0 50vw;
  top: 1vh;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: var(--primary);
  z-index: 1;
}
