@media screen {
  * {
    box-sizing: border-box;
  }
  svg {
    box-sizing: content-box;
  }
  :root {
    --background: white;
    --text: rgb(100, 100, 100);
    --error: #ff3c2e;
    --warning: #ffa42e;
    --caution: #ffcc8a;
    --dark: rgb(70, 70, 70);
    --primary: rgb(128, 183, 255);
    --secondary: rgb(221, 221, 221);
    --tertiary: rgb(240, 240, 240);
    --invis-borders: #e8e8e8;
    --partial: #dbf4ff;
    --allocated: #8cc63f;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background-color: transparent;
    color: var(--text);
    margin: 0;
  }
  p {
    color: var(--text);
    margin: 0;
  }
  b {
    color: var(--text);
  }
  .desc {
    border: solid 1px var(--secondary);
    border-radius: 5px;
    padding: 0.5rem;
    width: 100%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  /* input {
    box-sizing: border-box;
    border: solid 1px var(--secondary);
    border-radius: 2px;
    color: var(--text);
  } */

  [type="checkbox"]:hover {
    cursor: pointer;
  }

  select {
    box-sizing: content-box;
  }
  /* Navigation */
  header {
    background-color: var(--tertiary);
  }
  .active {
    display: flex !important;
    flex-direction: column;
  }

  .nav {
    display: flex;
    padding: 0;
    margin: 0;
  }
  .nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  .nav li {
    word-wrap: break-word;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .nav li:hover {
    background-color: var(--primary);
  }
  .nav .logo:hover {
    background-color: var(--primary);
  }
  .nav a {
    display: block;
    padding: 1rem 1rem;
    line-height: normal;
    text-decoration: none;
    color: var(--text);
  }
  .nav a:hover {
    background-color: rbg(240, 240, 240);
    color: var(--background);
  }
  .drop-down {
    position: relative;
    display: inline-block;
  }
  .drop-down-item {
    background-color: var(--primary);
    top: auto;
    position: absolute;
    display: none;
  }
  .drop-down > a:after {
    content: "  🡣";
  }
  li .drop-down:hover .drop-down-item {
    overflow: hidden;
    width: 100%;
    display: block;
  }

  /* Forms */
  .form-container {
    min-width: calc(100% - 6vw);
  }

  .form-shape {
    display: flex;
    flex-wrap: wrap;
    width: 200px;
  }

  .form-label {
    color: var(--text);
    display: block;
    font-weight: bold;
    white-space: nowrap;
  }
  .form-label-pair {
    color: var(--text);
    display: flex;
    font-weight: bold;
    white-space: nowrap;
  }
  .form-label-small {
    display: block;
    margin-bottom: 0.5rem;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    color: var(--secondary);
  }
  .form-autofill-container {
    position: relative;
    display: none;
  }

  .form-autofill {
    position: absolute;
    height: 20rem;
    overflow-y: scroll;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e7eef2;
    border-radius: 5px;
    z-index: 2;
  }

  .form-autofill-item {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #e7eef2;
    cursor: pointer;
    display: block;
    width: fit-content;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .input-option {
    display: flex;
    width: 100%;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    height: inherit;
    background-color: var(--tertiary);
    /* border-radius: 5px 0 0 5px; */
  }

  .input-variant-container {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    width: fit-content;
    border-radius: 5px;
  }
  .input-variant {
    border: none;
    font-size: 11pt;
    height: inherit;
    padding: 0.33rem 0.75rem;
    width: 100%;
  }
  .input-variant:focus {
    outline: none;
  }
  .input-variant-container:focus-within {
    outline: 2px solid #38464e;
  }

  .form-display-p {
    margin: 0 1rem;
    padding: 0.16rem 0;
  }
  .form-input-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }

  .form-input {
    display: block;
    flex-grow: 1;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }

  .input-digits {
    padding: 0.33rem 0.75rem;
    width: 5rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }

  .form-input-small {
    display: block;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }

  .form-input-medium {
    display: block;
    width: 20rem;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }

  .form-no-edit {
    display: flex;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: var(--text);
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--invis-borders) !important;
    border-radius: 5px;
  }

  .form-control {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }
  .form-textarea {
    width: 100%;
    display: block;
    border: 1px solid var(--secondary);
    border-radius: 3px;
    margin: 0;
    padding: 1rem 1rem;
    min-height: 5em;
    outline: none;
  }

  .bordered {
    border: solid 1px var(--secondary);
  }

  /* Selector */

  .selector-default {
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }

  .selector-container {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }

  .selector-group {
    display: block;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 5px;
  }

  .selector-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    width: fit-content;
    border-radius: 5px;
  }

  .selector-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 5px;
  }

  .selector-input-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 5px;
  }

  .selector-group:focus,
  .selector-input:focus,
  .selector-input-selector:focus {
    outline: none;
  }
  .selector-container:focus-within,
  .selector-input-container:focus-within {
    outline: 2px solid #38464e;
  }

  .selector-country-code-current {
    width: 205px; /* this is the width of an input */
    position: relative;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }
  .selector-country-code-list {
    position: absolute;
    z-index: 2;
    display: none;
    height: 30vh;
    overflow-y: scroll;
    border: 1px solid var(--secondary);
    border-radius: 5px;
  }
  .selector-country-code-item {
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border-bottom: 1px solid var(--secondary);
  }

  /* Page */
  .page {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  /* Shapes */
  .column {
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container {
    width: 100%;
    margin: 0.5rem 0;
    border-radius: 5px;
    display: flex;
  }
  .card {
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
  }
  .line {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .pair {
    display: flex;
    align-items: center;
  }
  .bar {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .smooth {
    border-radius: 5px;
  }

  .relative {
    position: relative;
  }

  /* Spacing */
  .off {
    margin: 0;
  }
  .heading {
    margin: 1rem 0;
  }
  .soft {
    padding: 0.5rem 0.5rem;
  }
  .heavy {
    padding: 1rem 1rem;
  }
  .sidebar {
    margin: 1rem 1rem;
  }
  .list {
    margin: 0 0 0.5rem 0;
  }
  .item {
    margin: 0 0.5rem;
  }
  .list-item {
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
  .square {
    margin: 0.5rem;
  }
  .middled {
    margin-left: 1rem;
  }
  .pushed {
    margin-right: 1rem;
  }

  /* Position */
  .left {
    justify-content: left;
  }
  .right {
    justify-content: right;
  }
  .center {
    justify-content: center;
  }
  .center-items {
    align-items: center;
  }
  .split {
    justify-content: space-between;
  }
  .bottom {
    align-items: flex-end;
  }

  /* Size */
  .one {
    flex: 1;
  }
  .two {
    flex: 2;
  }
  .three {
    flex: 3;
  }

  .hundred {
    flex-grow: 1;
    max-width: 100%;
  }

  .thirds-percent {
    width: 66%;
  }
  .onethird-percent {
    width: 32%;
  }
  .onethird {
    width: 33vw;
  }

  .min {
    min-width: 60vw;
  }

  /* Grid for Order Details Items*/
  .grid {
    display: grid;
    grid-template-columns: 2fr 5fr 4fr 5fr 1fr 2fr;
    width: 100%;
  }
  .grid-item {
    display: flex;
    padding: 0.25rem;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--secondary);
  }
  .grid-item-center {
    display: flex;
    padding: 0.25rem;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--secondary);
    align-items: center;
    justify-content: center;
  }

  /* Overflow */
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Buttons */
  /* .btn-default {
    display: inline-block;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    background: var(--background);
    color: var(--text);
    border: 1px solid var(--secondary);
    font-weight: 600;
    border-radius: 3px;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
  } */

  .btn-alt {
    display: inline-block;
    height: 33.5px;
    width: 45px;
    background: var(--background);
    border: 1px solid var(--secondary);
    cursor: pointer;
    border-radius: 3px;
  }

  /* .btn-primary {
    display: inline-block;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    background: var(--primary);
    color: var(--background);
    border: 1px solid var(--secondary);
    font-weight: 600;
    border-radius: 3px;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
  } */

  .btn-primary:hover {
    cursor: pointer;
    background: var(--background);
    color: var(--primary);
    border: 1px solid var(--primary);
  }
  .btn-alt:hover,
  .btn-default:hover {
    color: var(--primary);
    background: var(--tertiary);
    border-color: var(--primary);
  }

  .btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }

  .btn-icon-styled {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0.45rem;
    border-radius: 3px;
    background-color: white;
    border: 1px solid #c7c7c7;
    border-color: #8c8c8c;
  }

  .btn-icon:hover svg {
    /* stop-color: var(--primary); */
    /* flood-color: var(--primary); */
    /* lighting-color: var(--primary); */
    stroke: var(--primary);
  }

  .btn-icon-styled:hover {
    cursor: pointer;
    color: #38464e;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .btn-loginout {
    display: block;
    border: 1px solid var(--background);
    border-radius: 5px;
    padding: 1rem 1rem;
    line-height: normal;
    text-decoration: none;
    color: var(--text);
  }
  .btn-loginout:hover {
    background: var(--primary);
    padding: 1rem 1rem;
    line-height: normal;
    text-decoration: none;
    border: 1px solid var(--background);
    color: var(--background);
  }

  .btn-save-all {
    background-color: var(--primary);
    color: white;
    border-radius: 3px;
    border: 1px solid #c7c7c7;
    border-color: #8c8c8c;
  }
  .btn-save-all:hover {
    background-color: var(--primary);
    color: var(--primary);
    border-radius: 3px;
    border: 1px solid #c7c7c7;
    border-color: #8c8c8c;
    cursor: pointer;
  }

  .shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  }

  /* .icon {
    padding: 0.5rem 0.5rem;
  }
  .icon:hover {
    cursor: pointer;
  } */

  .drag-icon {
    display: block;
    padding: 0.5rem 0.5rem;
  }

  .drag-icon:hover {
    /* width: 100%; */
    cursor: grab;
  }

  /* Stacking */

  .deck {
    position: relative;
  }
  .stack {
    position: absolute;
    top: 0;
  }

  /* Custom Checkbox */
  .custom-checkbox {
    display: inline-block;
    margin: 3px;
    width: 13px;
    height: 13px;
    border-radius: 2px;
    border: solid 1px var(--text);
    cursor: pointer;
  }
  .custom-checkbox.checked {
    background-color: var(--primary);
  }
  /* Predictor */
  .timeline-predictor {
    position: relative;
    overflow: scroll;
    width: 100vw;
    height: 80vh;
    border: 2px solid #5e6d75;
    border-radius: 5px;
    color: #5e6d75;
    font-size: 12pt;
    display: inline-block;
  }

  .predictor-dateline {
    position: sticky;
  }

  .month {
    position: relative;
    height: 50px;
    font-weight: bold;
    font-size: 28pt;
    color: var(--text);
    padding: 0.5rem 0.5rem;
    border-bottom: solid 1px var(--secondary);
    border-right: solid 1px var(--secondary);
  }
  .month-text {
    position: absolute;
    top: 0;
  }

  .date-container {
    display: flex;
    height: inherit;
  }
  .day-container {
    display: flex;
    height: inherit;
  }
  .day-block {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .day {
    font-weight: bold;
    width: 100px;
    height: 100%;
    font-size: 18pt;
    color: var(--text);
    padding: 0.5rem 0.5rem;
    border-right: solid 1px var(--secondary);
  }

  .table-predictor {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 80vh;
    border: 2px solid #5e6d75;
    border-radius: 5px;
    color: #5e6d75;
    font-size: 12pt;
    display: inline-block;
  }

  .predictor-infinite {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .predictor-all {
    border: 1px solid var(--warning);
  }

  .predictor-container {
    position: absolute;
    display: flex;
  }

  .predictor-step {
    position: absolute;
    top: 0;
    background-color: var(--background);
    border-radius: 5px;
    overflow: hidden;
    z-index: 1;
  }

  .predictor-wire {
    position: absolute;
    top: 0;
  }

  .predictor-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 1rem;
    color: var(--background);
    background-color: var(--primary);
    border-radius: 5px 5px 0px 0px;
  }

  .predictor-employee-container {
    position: relative;
  }
  .predictor-employee-block {
    position: absolute;
    width: 100%;
    border: solid 2px var(--tertiary);
    border-radius: 5px;
    z-index: 2;
  }
  .predictor-employee-static {
    position: relative;
    white-space: nowrap;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: solid 1px var(--tertiary);
    color: var(--text);
    background-color: var(--background);
  }

  .predictor-employee {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--text);
    background-color: var(--background);
    border-radius: 5px;
    z-index: 2;
  }

  .predictor-employee-small {
    border: solid 1px var(--primary);
    padding-left: 1rem;
    border-radius: 5px;
  }

  /* Alerts */
  .alert-textarea {
    color: var(--text);
    border-radius: 3px;
    font-size: medium;
    width: 100%;
    height: 75px;
    resize: none;
  }

  .component-container {
    height: 20vh;
    flex-grow: 1;
  }

  .component-textarea {
    height: 100%;
    width: 100%;
    border: 1px solid var(--secondary);
    border-radius: 3px;
    margin: 0;
    padding: 1rem 1rem;
    min-height: 5em;
    outline: none;
    resize: none;
  }

  .table-container-component {
    overflow-y: scroll;
    min-width: 350px;
    flex-grow: 2;
    height: 20vh;
    border: 2px solid #5e6d75;
    border-radius: 5px;
    color: #5e6d75;
  }
  /* Tables */

  .table-boss {
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: var(--background);
  }

  .table-container {
    overflow: scroll;
    width: 100%;
    height: 80vh;
    border: 2px solid #5e6d75;
    border-radius: 5px;
    color: #5e6d75;
  }

  .table-sidebar-container {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 72vh;
    border: 2px solid #5e6d75;
    border-radius: 5px;
    color: #5e6d75;
  }

  .table-list-boss {
    width: 100%;
    height: 30vh;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
  }
  .table-list-container {
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: 30vh;
    border: 2px solid #5e6d75;
    border-radius: 5px;
    color: #5e6d75;
  }

  .table-cue {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    /* max-width: 50vw; */
    /* background-color: white; */
    z-index: 3;
    display: flex;
  }
  .batch-item > * {
    pointer-events: none;
  }

  .table {
    text-align: center;
    text-overflow: ellipsis;
    min-width: fit-content;
  }

  .header {
    position: sticky;
    width: 110%;
    overflow: hidden;
    top: 0;
    left: 0;
    display: flex;
    z-index: 2;
    text-overflow: ellipsis;
    background-color: var(--tertiary);
  }

  .header-cell {
    display: flex;
    align-items: center;
    height: 30px;
    min-width: min-content;
    position: relative;
    border-right: 1px solid #38464e;
    border-bottom: 1px solid #38464e;
  }

  .header-slide {
    position: absolute;
    top: 0;
    right: -10px;
    width: 20px;
    height: 30px;
    cursor: col-resize;
    z-index: 1;
  }

  .table-row {
    display: flex;
    text-overflow: ellipsis;
    width: 100%;
    height: 30px;
    align-items: center;
  }

  .table-row.warning {
    background-color: var(--warning);
  }
  .table-row.warning .table-item.click {
    color: var(--background);
  }
  .table-row.caution {
    background-color: var(--caution);
  }
  .table-row.caution .table-item.click {
    color: var(--text);
  }
  .table-row.partial {
    background-color: var(--partial);
  }

  .table-row.complete {
    background-color: var(--primary);
  }
  .table-row.complete .table-item.click {
    color: var(--background);
  }

  /* .table-row:hover {
    background-color: var(--tertiary);
  } */

  .table-reprint-row {
    display: flex;
    text-overflow: ellipsis;
    height: 30px;
    background-color: var(--warning);
    color: white;
  }

  .table-row > .table-cell {
    pointer-events: none;
  }

  .table-cell {
    display: block;
    white-space: nowrap;
    border-right: 1px solid #38464e;
    text-overflow: ellipsis;
    flex-shrink: 0;
    height: 30px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .table-cell-link {
    color: var(--primary);
  }
  .table-cell-primary {
    color: var(--primary);
  }
  .table-cell-allocated {
    color: var(--allocated);
  }
  .table-cell-overflow {
    color: var(--warning);
  }
  .table-cell-sku-header {
    display: flex;
    margin: 0 3rem;
    align-items: center;
    height: 30px;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table-cell-sku-header:hover {
    cursor: pointer;
  }

  .table-cell-sku {
    display: none;
    align-items: center;
    height: 30px;
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table-cell-sku-primary {
    display: flex;
    margin: 0 3rem;
    align-items: center;
    height: 30px;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .table-cell-link:hover {
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
  }

  .table-row.hidden {
    position: relative;
    top: 100%;
    left: 0;
    width: 100%;
    perspective: 1000px;
    display: none;
  }

  .table-item {
    display: block;
    white-space: nowrap;
    border-right: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
    text-overflow: ellipsis;
    flex-shrink: 0;
    height: 30px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }
  .click {
    cursor: pointer;
  }
  .click:hover {
    color: var(--primary);
  }

  .table-sticky-column {
    background-color: white;
    top: -1px;
    left: 0;
    position: sticky;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #38464e;
  }
  .table-sticky-cell {
    top: -1px;
    position: static;
    display: flex;
    border-right: 1px solid #38464e;
    border-bottom: 1px solid #38464e;
    text-overflow: ellipsis;
    flex-shrink: 0;
    height: 30px;
    overflow: clip;
    justify-content: center;
    align-items: center;
  }
  .table-sticky-cell:hover {
    overflow: visible;
  }

  .table-sticky-cell:hover .table-cell-explination {
    display: block;
    cursor: pointer;
  }

  .table-cell-explination {
    z-index: 3 !important;
    display: none;
    position: relative;
    min-width: max-content;
    padding: 0 0.5rem;
    left: 10rem;
    border-radius: 5px;
    color: white;
    background-color: #b43838;
    border: var(--dark);
    animation: explain 200ms ease-out forwards;
  }

  @keyframes explain {
    0% {
      trasnform: scaley(0);
      background-color: #fff;
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
      background-color: #b43838;
    }
  }

  .table-tag-cell {
    display: flex;
    position: relative;
    white-space: nowrap;
    border-right: 1px solid #38464e;
    border-bottom: 1px solid #38464e;
    text-overflow: ellipsis;
    flex-shrink: 0;
    height: 30px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .table-tag-container {
    display: flex;
    position: relative;
    justify-content: left;
    align-content: center;
  }

  .table-tag {
    z-index: 1;
    height: 25px;
    width: 10px;
    border-radius: 5px;
    margin-left: 1px;
  }
  /* TODO:: make tag info not bad */
  .tag-info {
    padding: 10px;
    top: auto;
    left: 20px;
    border-radius: 5px;
    position: absolute;
    text-align: center;
    white-space: nowrap;
    color: white;
    background-color: black;
    z-index: 2;
    opacity: 0;
    width: -moz-fit-content;
    width: fit-content;
  }

  .table-tag:hover {
    cursor: pointer;
  }

  .table-tag-cell:hover {
    overflow: visible;
  }

  .table-tag:hover + .tag-info {
    opacity: 1;
  }

  .table-row.animate {
    display: flex;
    border-bottom: 1px solid #38464e;
    animation: growDown 200ms ease-out forwards;
  }

  @keyframes growDown {
    0% {
      trasnform: scaley(0);
      background-color: #fff;
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
      background-color: rgb(239, 239, 239);
    }
  }

  /* Search Bar */
  .search-bar-container {
    display: flex;
    /* width: 100%; */
    border: 1px solid var(--secondary);
    border-radius: 3px;
  }

  .search-bar-input {
    display: block;
    flex-grow: 1;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: #38464e;
    background-color: #fff;
    background-image: none;
    border: none;
  }
  .search-bar-input:focus {
    outline: none;
  }
  .search-bar-container:focus-within {
    outline: 2px solid #38464e;
  }

  .search-bar-btn {
    display: inline-block;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    color: var(--primary);
    background: var(--btn-color);
    border: none;
    font-weight: 600;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
  }

  .search-bar-btn:hover {
    background: var(--primary);
    color: var(--background);
    cursor: pointer;
  }

  /* Modals */
  .modal-page {
    display: none;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    color: #000000;
  }

  .modal-state {
    position: fixed;
    display: block;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    color: #000000;
  }

  .modal {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
  }

  /* Barcodes */
  .barcode-modal-container {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    color: #000000;
  }

  /* .barcode-modal-container > div {
    pointer-events: none;
  } */

  .barcode-modal {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    z-index: 4;
  }

  .barcodes-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5rem;
  }
  .barcode {
    display: flex;
    align-items: center;
  }

  .barcode-img {
    padding: 1rem;
  }

  /* Queue Rolodex */
  .queue-rolo::-webkit-scrollbar {
    display: none;
  }
  .queue-rolo {
    display: none;
    z-index: 2;
    /* height: 300px; */
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    animation: fadein 200ms ease-out forwards;
  }
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .queue-input {
    width: 60px;
    margin-right: 1rem;
  }

  .queue-dex {
    text-align: center;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #c7c7c7;
    background-color: #bbb;
    background-image: none;
    background-color: transparent;
  }
  .queue-dex:hover {
    background-color: var(--primary);
    cursor: pointer;
  }

  .btn-load-queue {
    position: relative;
    width: 50%;
    height: 50px;
    margin-right: 25%;
    margin-left: 25%;
    top: 50%;
    display: block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    border-radius: 3px;
    padding: 0.33rem 0.75rem;
    font-size: 11pt;
    line-height: 1.428571429;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    cursor: pointer;
    background-image: none;
  }
  .btn-load-queue:hover {
    animation: fade 200ms ease-out forwards;
  }

  @keyframes fade {
    0% {
      box-shadow: 0 4px 8px 0 rgba(255, 255, 255),
        0 6px 20px 0 rgba(255, 255, 255);
    }
    100% {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.19),
        0 6px 20px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

/* Reprint */
.reprint-modal {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #000000;
}
.reprint-flash {
  border-radius: 5px;
  position: absolute;
  opacity: 0;
}

.reprint-pulse {
  animation: flash 0.5s ease-out;
  animation-fill-mode: backwards;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.reprint-modal-content {
  display: flex;
  position: relative;
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
}

.reprint-modal-input {
  position: absolute;
  left: 100px;
  z-index: -1;
}

.reprint-modal-position {
  flex-grow: 1;
  position: relative;
}

/* .reprint-modal-mask {
  border-radius: 5px;
  --height: 32px;
  position: absolute;
  top: calc(50% - var(--height));
  min-width: 100%;
  height: calc(var(--height) * 2);
  background-color: white;
  animation: pulse 1000ms infinite;
}

.reprint-modal-readybar {
  min-width: 100%;
  height: 64px;
  border-radius: 5px;
  background: linear-gradient(90deg, #38464e, var(--warning));
}
@keyframes pulse {
  0% {
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 0.1;
  }
} */

/* Visuals */
.strike {
  position: absolute;
  left: 0.5rem;
  background-color: var(--dark);
  height: 3px;
  width: 100%;
  z-index: 2;
  border-radius: 1.5px;
}

/* Spinner */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--dark);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Print View Barcodes */
@media print {
  .container {
    display: none;
  }
  .nav {
    display: none;
  }

  .barcode-nav {
    display: none;
  }
  button {
    display: none;
  }

  .barcodes-container {
    width: max-content;
    height: max-content;
  }

  .barcode-text {
    font-size: 8pt;
    width: 39mm;
    max-height: 26mm;
    position: absolute;
    top: 3mm;
    left: 20mm;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .barcode-img {
    transform: scale(0.25);
    position: relative;
    left: -55px;
    top: -40px;
    break-inside: avoid;
  }

  .pagebreak {
    position: relative;
    max-width: 55mm;
    max-height: 29mm;
    -webkit-break-after: always;
    break-after: always;
  }
}
