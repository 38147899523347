.expand {
  animation: expand 1s ease-in forwards;
  overflow: hidden;
  max-height: 21.6px;
}

@keyframes expand {
  0% {
    max-height: 21.6px;
  }
  100% {
    max-height: 3000px;
  }
}
