.global-alert {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
}

.alert-msg {
  white-space: nowrap;
  overflow: hidden;
}

.alert-error-animation {
  animation: init 0.5s ease;
  background: linear-gradient(30deg, #fff 0%, #ffb3ad 15%, var(--error) 100%);
}
.alert-success-animation {
  animation: init 0.5s ease;
  background: linear-gradient(30deg, #fff, var(--primary));
}
.alert-clear-animation {
  animation: clear 0.5s ease;
  background-color: white;
}
@keyframes clear {
  from {
    opacity: 1;
    transform: translatex(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes init {
  from {
    opacity: 0;
    transform: translatex(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.alert-error {
  color: var(--text);
}
.alert-success {
  color: var(--text);
}

.alert-error-idle {
  background: linear-gradient(30deg, #fff 0%, #ffb3ad 15%, var(--error) 100%);
  color: white;
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}
.alert-success-idle {
  background: linear-gradient(30deg, #fff, var(--primary));
  color: white;
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}

.alert-clear-idle {
  background-color: white;
}

@keyframes gradient {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 300% 300%;
  }
  100% {
    background-size: 100% 100%;
  }
}
